import * as React from "react";
import classNames from "classnames";
// import LoaderLogo from './loader-logo.svg';
const LoaderLogo = () => (
  <svg viewBox="0 0 26 16" xmlns="http://www.w3.org/2000/svg">
    <path
      className="loader-logo__item loader-logo__item--1"
      d="m8.8709 0c-1.026 0-1.8709 0.896-1.8709 2.0053 0 1.0987 0.84493 1.9947 1.8709 1.9947h8.2682c1.0159 0 1.8609-0.896 1.8609-2.0053 0-1.0987-0.84493-1.9947-1.8609-1.9947h-8.2682z"
    />
    <path
      className="loader-logo__item loader-logo__item--2"
      d="m20.189 9.9894c1.0002 0 1.8111-0.89305 1.8111-1.9947 0-1.1016-0.81086-1.9947-1.8111-1.9947h-14.392c-0.9949 0-1.7966 0.89362-1.7966 2s0.80172 2 1.7966 2h14.392v-0.010638z"
    />
    <path
      className="loader-logo__item loader-logo__item--3"
      d="m15.195 16c0.99512 0 1.8049-0.896 1.8049-2.0053 0-1.0987-0.80976-1.9947-1.8049-1.9947h-4.4098c-0.97561 0-1.7854 0.896-1.7854 2.0053 0 1.0987 0.80976 1.9947 1.7854 1.9947h4.4098z"
    />
  </svg>
);

export enum LoaderSize {
  SMALL,
  MEDIUM,
  LARGE,
}

export enum LoaderColor {
  PRIMARY,
}

export enum LoaderShape {
  DOTS,
  LOGO,
  JUMPING_DOTS,
}

const mapSizeToClassName: { [key in LoaderSize]: string } = {
  [LoaderSize.SMALL]: "loader--size-s",
  [LoaderSize.MEDIUM]: "loader--size-m",
  [LoaderSize.LARGE]: "loader--size-l",
};

const mapColorToClassName: { [key in LoaderColor]: string } = {
  [LoaderColor.PRIMARY]: "loader--primary",
};

const mapShapeToClassName: { [key in LoaderShape]: string } = {
  [LoaderShape.DOTS]: "loader--dots",
  [LoaderShape.LOGO]: "loader--logo",
  [LoaderShape.JUMPING_DOTS]: "loader--jumping-dots",
};

type LoaderProps = {
  size?: LoaderSize;
  color?: LoaderColor;
  shape?: LoaderShape;
  isAnimated?: boolean;
  extraClass?: string;
  jsClass?: string;
};

const Loader: React.FunctionComponent<LoaderProps> = (props) => {
  const {
    size = LoaderSize.MEDIUM,
    color,
    shape = LoaderShape.DOTS,
    isAnimated = true,
    extraClass,
    jsClass,
  } = props;
  const classnames = {
    block: classNames(
      {
        loader: true,
        [mapSizeToClassName[size]]: true,
        [mapShapeToClassName[shape]]: true,
        [extraClass as string]: extraClass,
        [jsClass as string]: jsClass,
        "is-animated": isAnimated,
      },
      typeof color !== "undefined" && mapColorToClassName[color],
    ),
  };

  // TODO: Make svg-animations instead of css
  return (
    <div className={classnames.block}>
      {shape === LoaderShape.LOGO ? <LoaderLogo /> : null}

      {shape !== LoaderShape.LOGO ? <div className="loader__inner" /> : null}
    </div>
  );
};

export default Loader;
