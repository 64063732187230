import React from "react";

type MultiPhotoSlideProps = {
  index?: string | number;
  mediaURL?: string;
  title?: string;
  ctaLink?: string;
};

function MultiPhotoSlide(props: MultiPhotoSlideProps) {
  return (
    <li className="glide__slide" data-key={props.index} key={props.index}>
      {props.ctaLink ? (
        <>
          <a href={`${props.ctaLink}`} target="_blank" rel="noreferrer">
            <div
              className="post-image"
              style={{
                width: "100%",
                backgroundImage: `url("${props.mediaURL}")`,
              }}
            />
          </a>
          <a
            className="post-title-link"
            href={`${props.ctaLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="do-title post-title beehive-h3">{props.title}</div>
          </a>
        </>
      ) : (
        <>
          <div
            className="post-image"
            style={{
              width: "100%",
              backgroundImage: `url("${props.mediaURL}")`,
            }}
          />
          <span className="post-title-link">
            <div className="do-title post-title beehive-h3">{props.title}</div>
          </span>
        </>
      )}
    </li>
  );
}

export { MultiPhotoSlide };
