import * as React from "react";
import Link from "components/link/link";

type Props = {
  items?: {
    url?: string;
    title?: string | React.ReactNode;
    onClick?: (event: React.MouseEvent) => void;
  }[];
  url?: string;
  title?: string | React.ReactNode;
  onClickMenuLink?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  seo?: {
    name?: string;
    category?: string;
    action?: string;
    label?: string;
    source?: string;
  };
};

function MobileMenu(props: Props) {
  const {
    title,
    url,
    onClickMenuLink,
    items,
    seo = {
      name: null,
      category: null,
      action: null,
      label: null,
      source: null,
    },
  } = props;
  return (
    <nav className="mobile-menu">
      <div className="mobile-menu__title">
        <Link
          className="link"
          href={url}
          onClick={onClickMenuLink}
          data-seo-name={seo.name}
          data-seo-category={seo.category}
          data-seo-action={seo.action}
          data-seo-label={seo.label}
          data-source={seo.source}
        >
          <span className="link__text">{title}</span>
        </Link>
      </div>

      {items ? (
        <ul className="mobile-menu__list">
          {items.map((item, index) => {
            const { url: itemUrl, title: itemTitle, onClick } = item;

            return (
              <li
                className="mobile-menu__item"
                key={`mobile-menu-item-${index}`}
              >
                {onClick ? (
                  <span className="mobile-menu__link" onClick={onClick}>
                    {itemTitle}
                  </span>
                ) : (
                  <Link className="mobile-menu__link" href={itemUrl}>
                    {itemTitle}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      ) : null}
    </nav>
  );
}

export default MobileMenu;
