import * as React from "react";
import cx from "classnames";

export enum CtaBoxTitleAlign {
  CENTERED = "CENTERED",
  NONE = "NONE",
}

const mapAlignToClasses = {
  [CtaBoxTitleAlign.CENTERED]: "cta-box__title--centered",
  [CtaBoxTitleAlign.NONE]: "",
};

type CtaBoxTitleProps = {
  align?: CtaBoxTitleAlign;
  marginTop?: number;
  children?: React.ReactChild | React.ReactChild[];
};
const CtaBoxTitle: React.FC<CtaBoxTitleProps> = (props) => {
  const { align = CtaBoxTitleAlign.NONE, children, marginTop } = props;
  const classname = cx({
    "cta-box__title": true,
    [mapAlignToClasses[align]]: true,
  });
  const style = {
    marginTop,
  };
  return (
    <div className={classname} style={style}>
      {children}
    </div>
  );
};

export default CtaBoxTitle;
