import * as React from "react";
import { Categories } from "lib/api";
import {
  Category,
  CategoryEdge,
} from "components/seasonal-content-hub/card/card/category";
import { Card } from "components/seasonal-content-hub/card/card/card";
import {
  CategoryToPostConnection,
  CategoryToAncestorsCategoryConnectionEdge,
  Maybe,
  WpPageInfo,
  AcfLink,
  CategoryToPostConnectionEdge,
  Scalars,
} from "interfaces/bumble-bff-landing-microsite-cms";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import Loader, { LoaderSize } from "components/mock-loader";

export type PageCategoriesList = {
  edges: CategoryEdge[];
  pageInfo?: Maybe<WpPageInfo>;
};

export type SetEdgePost = (
  value: Maybe<CategoryToPostConnectionEdge> | null,
  index: number,
) => React.ReactNode;

export function setEdgePost(
  edge: Maybe<CategoryToPostConnectionEdge> | null,
  index: number,
): React.ReactNode {
  if (!edge) {
    return <></>;
  }

  const { node } = edge;

  if (!node) {
    return <></>;
  }
  return (
    <React.Fragment key={`category-post-index-${index}`}>
      <Card
        title={node.title || undefined}
        excerpt={node.excerpt || undefined}
        src={node.featuredImage?.node?.mediaItemUrl || undefined}
        altText={node.featuredImage?.node?.altText || undefined}
        width={"282"}
        ctaText={node.postFieldGroup?.ctaText || ""}
        url={node.uri || undefined}
        ctaUrl={(node.postFieldGroup?.ctaUrl as string) || ""}
      />
    </React.Fragment>
  );
}

type MoreCategoryPostsProps = {
  titleUrl?: string;
  breadcrumbs?: React.ReactNode;
  edge: CategoryEdge;
  pageInfo?: Maybe<WpPageInfo>;
  name: Maybe<string> | undefined;
  slug: Maybe<string> | undefined;
  ctaUrl: AcfLink & string;
  ctaText: string;
  backgroundcolor?: string;
  hideTitle?: boolean;
  hideCta?: boolean;
  isRelatedArticles?: boolean;
  filter?: Scalars["Int"];
  isCategoryPage?: boolean;
};

export const MoreCategoryPosts = ({
  titleUrl,
  breadcrumbs,
  edge,
  pageInfo,
  name,
  slug,
  ctaUrl,
  ctaText,
  backgroundcolor,
  hideTitle,
  hideCta,
  isRelatedArticles,
  filter,
  isCategoryPage,
}: MoreCategoryPostsProps) => {
  const [hasMoreArticles, setHasMoreArticles] = React.useState(
    pageInfo?.hasNextPage,
  );
  const [endCursor, setEndCursor] = React.useState(pageInfo?.endCursor);
  const initialEdges = {
    edges: isCategoryPage
      ? edge.node.posts.edges
      : edge.node.posts.edges
          .filter((post) => post?.node?.databaseId !== filter)
          .filter((_item, index) => index < 4),
  };

  const [recentEdges, setRecentPosts] = React.useState<
    CategoryToPostConnection | undefined
  >(edge ? initialEdges : undefined);

  const [isLoading, setIsLoading] = React.useState(false);

  const loadMoreArticles = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setIsLoading(true);

      if (!endCursor) {
        setIsLoading(false);
        return;
      }

      if (name) {
        getMoreCategoryPosts(name as Categories, endCursor, filter).then(
          (moreRecentEdges) => {
            if (!moreRecentEdges) {
              return;
            }

            if (
              moreRecentEdges.edges &&
              moreRecentEdges.edges[0]?.node?.posts?.edges
            ) {
              const newRecentEdges = { ...recentEdges };

              newRecentEdges.edges =
                recentEdges && recentEdges.edges
                  ? [
                      ...recentEdges.edges,
                      ...moreRecentEdges.edges[0].node.posts.edges,
                    ]
                  : moreRecentEdges.edges[0].node.posts.edges;

              setIsLoading(false);
              setHasMoreArticles(moreRecentEdges.hasNextPage);
              setEndCursor(moreRecentEdges.endCursor);
              setRecentPosts(newRecentEdges);
            }
          },
        );
      }
    },
    [name, endCursor, recentEdges, filter],
  );

  const cta =
    hasMoreArticles && !isLoading ? (
      <Button
        color={Button.color.Primary}
        text="More articles"
        labelText="More articles"
        extraClass="button--cta-blog"
        icon={<Icon size="sm" name={Icon.Name.Plus} />}
        onClick={loadMoreArticles}
      />
    ) : null;

  return (
    <React.Fragment>
      <Category
        breadcrumbs={breadcrumbs}
        titleUrl={titleUrl}
        name={hideTitle ? undefined : name || undefined}
        slug={slug || undefined}
        edges={
          (recentEdges &&
            recentEdges.edges &&
            recentEdges.edges.map(setEdgePost)) ||
          undefined
        }
        ctaUrl={ctaUrl}
        ctaText={ctaText}
        backgroundColor={backgroundcolor}
        hasOneRow={true}
        cta={hideCta ? undefined : cta}
        loader={isLoading ? <Loader size={LoaderSize.LARGE} /> : null}
        isRelatedArticles={isRelatedArticles}
      />
    </React.Fragment>
  );
};

export function setMoreCategoryPostsList({
  edge,
  index,
  breadcrumbs,
  backgroundColor,
  hideTitle,
  hideCta,
  isRelatedArticles,
  filter,
  isCategoryPage,
  titleUrl,
}: {
  edge: CategoryEdge;
  index: number;
  breadcrumbs?: React.ReactNode;
  backgroundColor?: string;
  hideTitle?: boolean;
  hideCta?: boolean;
  isRelatedArticles?: boolean;
  filter?: Scalars["Int"];
  isCategoryPage?: boolean;
  titleUrl?: string;
}) {
  const {
    node: {
      name,
      slug,
      postCategoryFieldGroup: { ctaUrl, ctaText },
      posts: { pageInfo },
      postCategoryFieldGroup: { backgroundcolor },
    },
  } = edge;

  return (
    <React.Fragment key={`category-list-${index}`}>
      <MoreCategoryPosts
        breadcrumbs={breadcrumbs}
        edge={edge}
        pageInfo={pageInfo}
        name={name}
        slug={slug}
        ctaUrl={ctaUrl}
        ctaText={ctaText}
        backgroundcolor={backgroundColor || backgroundcolor}
        hideTitle={hideTitle}
        hideCta={hideCta}
        isRelatedArticles={isRelatedArticles}
        filter={filter}
        isCategoryPage={isCategoryPage}
        titleUrl={titleUrl}
      />
    </React.Fragment>
  );
}

export function getMoreCategoryPosts(
  category: Categories | null,
  after: string,
  filter?: Scalars["Int"],
): Promise<{
  edges?: Maybe<Maybe<CategoryToAncestorsCategoryConnectionEdge>[]> | undefined;
  hasNextPage: boolean;
  endCursor: string;
}> {
  let url = `/bff/api/category-posts?category=${category}&after=${after}`;

  if (filter) {
    url += `&tagSlugNotIn${filter}`;
  }

  return fetch(url).then((blob) => blob.json());
}
