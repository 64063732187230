import * as React from "react";
import lexemes from "components/lexemes/index/lexemes";
import LocaleContext from "lib/locale-context";
import { Lexeme } from "@bumble/localization/components";
import CookieNotification from "components/cookie-notification/cookie-notification";
import useMediaQuery, { MediaQueryWidth } from "lib/use-media-query";

const PageCookie = () => {
  const lang = React.useContext(LocaleContext);
  const isBreakpoint = useMediaQuery(MediaQueryWidth["desktop-sm"]);
  const cookiePolicyLink = `https://bumble.com/${lang}/cookie-policy`;
  const cookieNotificationTitle = isBreakpoint ? (
    <Lexeme path="bumblesite@team_bumble.page_index.cookie.banner.title" />
  ) : null;
  const cookieNotificationText = lexemes.lxCookieBannerText({
    cookiePolicyLink,
  });

  return (
    <CookieNotification
      title={cookieNotificationTitle}
      text={cookieNotificationText}
    />
  );
};

export default PageCookie;
