import { decode as decodeFromLib } from "html-entities";
import { log } from "lib/log";

export function decode(text: string) {
  log(`input html ==> ${text}`);
  log(`output text ==> ${decodeFromLib(text, { level: "html5" })}`);

  /**
   * @TODO: add content sanitizer
   */

  return decodeFromLib(text, { level: "html5" }) as string;
}

export function decodePossible(text?: string) {
  if (!text) {
    return undefined;
  }

  return decode(text);
}
