import * as React from "react";
import Link from "components/link/link";
import { P1 } from "components/typography";
import Image from "lib/image-container";

type CardProps = {
  src?: string;
  altText?: string;
  width?: string;
  title?: string;
  excerpt?: string;
  url?: string;
  ctaUrl?: string;
  ctaText?: string;
};

export function Card({
  src,
  altText = "",
  width,
  title,
  excerpt,
  ctaUrl,
  ctaText,
  url,
}: CardProps) {
  const cardContent = (
    <>
      <div className="card__header">
        {src ? (
          <div className="card__media">
            <Image
              src={src}
              alt={altText}
              //   layout="responsive"
              layout="fill"
              objectFit="cover"
              // send dynamic ratio 1:1
              width={typeof width === "number" ? width : undefined}
              height={typeof width === "number" ? width : undefined}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 33vw, 33vw"
            />
          </div>
        ) : null}
      </div>

      <div className="card__content">
        <div className="card__title">
          <P1>
            <b>{title}</b>
          </P1>
        </div>

        {excerpt ? (
          <div className="card__description">
            <P1 html={excerpt.replace("[&hellip;]", "...")} />
          </div>
        ) : null}

        {ctaUrl || url ? (
          <div className="card__cta">
            <span className="link">{ctaText}</span>
          </div>
        ) : null}
      </div>
    </>
  );

  return ctaUrl || url ? (
    <Link className="card" href={ctaUrl || url} target="_blank">
      {cardContent}
    </Link>
  ) : (
    <div className="card">{cardContent}</div>
  );
}
