import * as React from "react";
import PropTypes from "prop-types";
import supportedLanguageIds, {
  SupportedLanguage,
} from "lib/lexemes/supported-languages";

// See corresponding unit test for all the test cases
function Link(
  props: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > & {
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  },
) {
  let href = props.href || "";

  // trim trailing slash for non-root urls
  if (href.length > 1 && href.endsWith("/")) {
    href = href.substr(0, href.length - 1);
  }

  if (!href.startsWith("http")) {
    // If link doesn't start with http, log warning and convert to local link
    if (!(href.startsWith("/") || href === "#")) {
      // console.warn('Invalid link. Must be a local or valid http link', href);
      href = `/${href}`;
    }
  }

  return <a {...props} href={href} onClick={props.onClick} />;
}

Link.props = {
  href: PropTypes.string,
  "data-href": PropTypes.string,
  rel: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
  classname: PropTypes.string,
};

export default Link;

export function getLanguageFromUrl(urlStr: string) {
  // Filter out empty and dynamic routes
  const split = urlStr
    .split("/")
    .filter((path) => path !== "")
    .filter((path) => !/\[.+?\]/.test(path));

  // extract language
  const splitPart = split.shift();
  if (
    splitPart &&
    supportedLanguageIds.includes(splitPart as SupportedLanguage)
  ) {
    return splitPart as SupportedLanguage;
  }

  return null;
}
