import * as React from "react";
import cx from "classnames";
import { Header3 } from "components/typography";
import {
  Category as CategoryType,
  Post,
} from "interfaces/bumble-bff-landing-microsite-cms";
import CtaBoxCaption, {
  CtaBoxCaptionAlign,
} from "components/cta-box/cta-box-caption";
import CtaBox, { CtaBoxAlign } from "components/cta-box/cta-box";

export type CategoryEdgePostEdge = {
  node: Post & {
    postFieldGroup: {
      ctaText: string;
      ctaUrl: string;
    };
  };
};

export type CategoryEdge = {
  node: CategoryType & {
    postCategoryFieldGroup: {
      order: number;
      backgroundcolor?: string;
      ctaText: string;
      ctaUrl: string;
      showonmainpage: boolean;
    };
    posts: {
      edges: CategoryEdgePostEdge[];
    };
  };
};

export type CategoryPostItem = {
  node: Post & {
    postFieldGroup: {
      ctaText: string;
      ctaUrl: string;
    };
  };
};

type CategoryProps = {
  titleUrl?: string;
  breadcrumbs?: React.ReactNode;
  cta?: React.ReactNode;
  loader?: React.ReactNode;
  name?: string;
  slug?: string;
  edges?: CategoryEdge[] | React.ReactNode[];
  ctaUrl?: string;
  ctaText?: string;
  backgroundColor?: string;
  hasOneRow?: boolean;
  isRelatedArticles?: boolean;
};

export function Category({
  breadcrumbs,
  name,
  slug,
  edges,
  backgroundColor,
  hasOneRow,
  cta,
  loader,
  isRelatedArticles,
}: CategoryProps) {
  const className = cx({
    category: true,
    "category--one-row": hasOneRow,
    "category--related-articles": isRelatedArticles,
    [`category--${name?.toLocaleLowerCase()}`]: name,
  });

  const title = isRelatedArticles ? `More ${name} Date Ideas` : name;
  const url = slug;

  return (
    <div
      className={className}
      style={{
        backgroundColor,
      }}
    >
      <div className="category__breadcrumbs">{breadcrumbs}</div>
      <div className="category__header">
        <Header3>{url ? <a href={url}>{title}</a> : title}</Header3>
      </div>
      <div className="category__content">
        <div className="category__cards">{edges as React.ReactNode[]}</div>
      </div>

      {loader ? <div className="category__cta">{loader}</div> : null}

      {cta ? (
        <div className="category__cta">
          <CtaBox align={CtaBoxAlign.CENTERED}>
            <CtaBoxCaption align={CtaBoxCaptionAlign.CENTERED}>
              <>{cta}</>
            </CtaBoxCaption>
          </CtaBox>
        </div>
      ) : null}
    </div>
  );
}
