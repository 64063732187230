import * as React from "react";
import {
  PageCategoriesList,
  setMoreCategoryPostsList,
} from "components/more-category-posts/more-category-posts";
import { Scalars } from "interfaces/bumble-bff-landing-microsite-cms";

type PageIndexCategoryListProps = {
  pageCategoriesList: PageCategoriesList;
  backgroundColor?: string;
  filter?: Scalars["Int"];
  hideTitle?: boolean;
  hideCta?: boolean;
  isRelatedArticles?: boolean;
};

export function PageIndexCategoryList({
  pageCategoriesList,
  backgroundColor,
  filter,
  hideTitle,
  hideCta,
  isRelatedArticles,
}: PageIndexCategoryListProps) {
  return (
    <React.Fragment>
      {pageCategoriesList?.edges
        ?.sort(
          (edgePrev, edgeNext) =>
            edgePrev?.node?.postCategoryFieldGroup.order -
            edgeNext?.node?.postCategoryFieldGroup.order,
        )
        .filter(
          (edgeItem) => edgeItem.node?.postCategoryFieldGroup.showonmainpage,
        )
        .map((category, index) =>
          setMoreCategoryPostsList({
            edge: category,
            index,
            backgroundColor,
            hideTitle,
            hideCta,
            isRelatedArticles,
            filter,
          }),
        )}
    </React.Fragment>
  );
}
