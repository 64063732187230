import * as React from "react";
import Text, { TextType, TextProps } from "components/typography/text";

const Header4: React.FC<TextProps> = (props) => (
  <Text type={TextType.H4} {...props} isResponsive={props.isResponsive || true}>
    {props.children}
  </Text>
);

export default Header4;
