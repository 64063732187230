import * as React from "react";
import cx from "classnames";
import { Theme, mapThemeToClassNames } from "ui/themes";

enum PageSectionBackground {
  NONE = "NONE",
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  ACCENT = "ACCENT",
  SEMI_COVERED = "SEMI_COVERED",
}

export const mapBackgroundToClassname: Record<PageSectionBackground, string> = {
  [PageSectionBackground.NONE]: "",
  [PageSectionBackground.SEMI_COVERED]: "page-section--primary-partly",
  [PageSectionBackground.PRIMARY]: "page-section--primary",
  [PageSectionBackground.SECONDARY]: "page-section--secondary",
  [PageSectionBackground.ACCENT]: "page-section--accent",
};

export type PageSectionProps = {
  children?: React.ReactNode;
  splashScreen?: boolean;
  inner?: boolean;
  theme?: Theme;
  extraClass?: string;
  jsClass?: string;
  isFullWidth?: boolean;
  hasPadding?: boolean;
  hasOnlyTopPadding?: boolean;
  hasFullWidthOnMobile?: boolean;
  bg?: PageSectionBackground;
};

function PageSection(props: PageSectionProps) {
  const {
    children,
    theme = Theme.NONE,
    bg = PageSectionBackground.NONE,
    splashScreen,
    inner = true,
    isFullWidth,
    hasPadding,
    hasOnlyTopPadding,
    extraClass,
    jsClass,
    hasFullWidthOnMobile,
  } = props;

  const className = cx({
    "page-section": true,
    "page-section--splash-screen": splashScreen,
    "page-section--full-width": isFullWidth,
    "page-section--padded": hasPadding,
    "page-section--padded-only-top": hasOnlyTopPadding,
    "page-section-mobile-full-width": hasFullWidthOnMobile,
    [mapThemeToClassNames[theme]]: true,
    [mapBackgroundToClassname[bg]]: true,
    [extraClass as string]: extraClass,
    [jsClass as string]: jsClass,
  });

  const content = !inner ? (
    children
  ) : (
    <div className="page-section__inner">{children}</div>
  );

  return <section className={className}>{content}</section>;
}

PageSection.Theme = Theme;
PageSection.Background = PageSectionBackground;

export default PageSection;
