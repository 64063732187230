import { GetServerSidePropsContext } from "next";
import { getLocale } from "lib/localization";
import { SupportedLanguage } from "lib/lexemes/supported-languages";
import { supportedLanguages } from "lib/supported-languages";

const urlToString = (url: string) => url.toString().replace(",", "/");

function setItemsToString(list: string[]) {
  const items = list.map((item) => urlToString(item));

  return items.toString();
}

function makeUrlFromQueryParam(param: string | string[]) {
  if (!param) {
    return "";
  }

  return typeof param === "string"
    ? urlToString(param)
    : setItemsToString(param);
}

export async function setupLangAndUrlParams(
  query: GetServerSidePropsContext<{
    lang: SupportedLanguage;
    slug: string | string[];
  }>["query"],
  locale?: SupportedLanguage,
) {
  let redirectToMainPageWithLocalisation = false;
  let lang: SupportedLanguage = "en";
  // add tests to cover
  // @ts-ignore
  let url = `/${makeUrlFromQueryParam(query.lang)}/${makeUrlFromQueryParam(
    // @ts-ignore
    query.slug,
  )}`;

  /**
   * case for index page with undefined language
   */
  if (Object.values(query).length === 0 || (!query.lang && !query.slug)) {
    redirectToMainPageWithLocalisation = true;
  } else if (
    typeof query.lang === "string" &&
    supportedLanguages.includes(query.lang as SupportedLanguage)
  ) {
    lang = getLocale(undefined, query);

    url = query.slug ? `/${query.slug.toString().replace(",", "/")}` : "";
  } else if (locale) {
    lang = locale;

    // @ts-ignore
    url = `/${makeUrlFromQueryParam(query.lang)}/${makeUrlFromQueryParam(
      // @ts-ignore
      query.slug,
    )}`;
  } else if (!query.lang) {
    const slug = (
      query.slug === "string" ? query.slug : query.slug && query.slug[0]
    ) as SupportedLanguage;

    if (supportedLanguages.includes(slug)) {
      lang = slug;

      // @ts-ignore
      url = `/${query.slug.toString().replace(",", "/")}`;
      redirectToMainPageWithLocalisation = true;
    }
  }

  /**
   * corner case, when lang/locale is not defined
   */
  url = url.replace("//", "/");

  /**
   * case, when we have lang at the end of url
   */
  if (
    // query.lang === undefined &&
    supportedLanguages.includes(
      Array.isArray(query.slug) && query.slug[0]
        ? (query.slug[0] as SupportedLanguage)
        : "en",
    )
  ) {
    // should be string here
    // @ts-ignore
    lang =
      Array.isArray(query.slug) && query.slug[0]
        ? query.slug[0]
        : query.lang || "en";
    url =
      !query.lang ||
      supportedLanguages.includes(query.lang as SupportedLanguage)
        ? "/"
        : `/${query.lang}`;
  }

  return {
    lang,
    url,
    redirectToMainPageWithLocalisation,
  };
}

export function cleanUrlFromParams(url: string) {
  return url.split("?")[0];
}
