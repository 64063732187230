import * as React from "react";
import { BlockProps } from "../index.types";
import { decodePossible } from "lib/decode";
import PageSection from "components/success-stories/page-section/page-section";
import SuccessStories from "components/success-stories/success-stories/success-stories";
import SuccessStory from "components/success-stories/success-story/success-story";

type BlockPropsType = {
  block: BlockProps;
} & {
  title?: string;
  subTitle?: string;
  text?: string;
  ctaText?: string;
  ctaLink?: string;
  // @TODO add html based check
  ctaTarget?: string;
  mediaURL?: string;
  // @TODO add noreferrer - here and at BE ?
};

const INIT_ITEM = {
  title: "",
  text: "",
};

function setSuccessStoryList(data: BlockProps["block"]["innerBlocks"]) {
  const list: BlockPropsType[] = [];

  data?.forEach((innerBlock) => {
    const listItem: BlockPropsType = Object.create(INIT_ITEM);

    const mediaURL = innerBlock?.attributes?.filter(
      (attribute) => attribute?.name === "mediaURL",
    )[0]?.value;

    if (mediaURL) {
      listItem.mediaURL = mediaURL;
    }

    const title = innerBlock?.attributes?.filter(
      (attribute) => attribute?.name === "title",
    )[0]?.value;

    if (title) {
      listItem.title = decodePossible(title);
    }

    const subTitle = innerBlock?.attributes?.filter(
      (attribute) => attribute?.name === "subTitle",
    )[0]?.value;

    if (subTitle) {
      listItem.subTitle = decodePossible(subTitle);
    }

    const text = innerBlock?.attributes?.filter(
      (attribute) => attribute?.name === "text",
    )[0]?.value;

    if (text) {
      listItem.text = decodePossible(text);
    }

    const ctaText = innerBlock?.attributes?.filter(
      (attribute) => attribute?.name === "ctaText",
    )[0]?.value;

    if (ctaText) {
      listItem.ctaText = decodePossible(ctaText);
    }

    const ctaLink = innerBlock?.attributes?.filter(
      // @TODO fix on BE and on FE -> clatLink to ctaLink
      (attribute) => attribute?.name === "clatLink",
    )[0]?.value;

    if (ctaLink) {
      listItem.ctaLink = decodePossible(ctaLink);
    }

    const ctaTarget = innerBlock?.attributes?.filter(
      (attribute) => attribute?.name === "ctaTarget",
    )[0]?.value;

    if (ctaTarget) {
      listItem.ctaTarget = decodePossible(ctaTarget);
    }

    list.push(listItem);
  });

  return list;
}

type SuccessStoriesType = BlockProps & BlockPropsType;

export default function SuccessStoriesBlock({ block }: SuccessStoriesType) {
  const stories = setSuccessStoryList(block.innerBlocks);

  return (
    <PageSection bg={PageSection.Background.NONE} hasPadding={true}>
      <SuccessStories>
        {stories.map((story, index) => (
          <SuccessStory {...story} key={`${story.title}-${index}`} />
        ))}
      </SuccessStories>
    </PageSection>
  );
}
