import * as React from "react";
import cx from "classnames";
import { Theme, mapThemeToClassNames } from "ui/themes";
// import GoToTopButton from 'components/go-to-top-button/go-to-top-button';

type Props = {
  header?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
  progress?: React.ReactNode;
  mobilePanel?: React.ReactNode;
  modal?: React.ReactNode;
  cta?: React.ReactNode;
  cookieNotification?: React.ReactNode;
  theme?: Theme;
  extraClass?: string;
  jsClass?: string;
  contentCenter?: boolean;
  headerCenter?: boolean;
  isGlowing?: boolean;
  onPageClick?: () => void;
};

function Page(props: Props) {
  const {
    header,
    content,
    footer,
    progress,
    mobilePanel,
    modal,
    cta,
    cookieNotification,
    theme = Theme.DATE,
    extraClass,
    jsClass = "js-mobile-panel-parent",
    contentCenter,
    headerCenter,
    onPageClick,
  } = props;

  const className = {
    block: cx({
      page: true,
      [mapThemeToClassNames[theme]]: true,
      [extraClass as string]: extraClass,
      [jsClass]: jsClass,
    }),
    header: cx({
      page__header: true,
      "page__header--center": headerCenter,
    }),
    content: cx({
      page__content: true,
      "page__content--align-center": contentCenter,
    }),
  };

  return (
    <main className={className.block} onClick={onPageClick}>
      {header ? <header className={className.header}>{header}</header> : null}
      {content ? <div className={className.content}>{content}</div> : null}
      {footer ? <footer className="page__footer">{footer}</footer> : null}
      {progress ? <div className="page__progress">{progress}</div> : null}
      {mobilePanel ? (
        <div className="page__mobile-panel">{mobilePanel}</div>
      ) : null}
      {modal ? <div className="page__modal">{modal}</div> : null}
      {cta ? (
        <div
          className="page__modal-content"
          style={{ display: "none" }}
          area-hidden="true"
        >
          <div data-modal-container="js-form-download-app">{cta}</div>
        </div>
      ) : null}
      {cookieNotification ? (
        <div className="page__cookie-notification js-cookie-notification">
          {cookieNotification}
        </div>
      ) : null}
      {/* <GoToTopButton /> */}
    </main>
  );
}

Page.Theme = Theme;

export default Page;
