import * as React from "react";
import Text, { TextType, TextProps } from "components/typography/text";

const Header1: React.FC<TextProps> = (props) => {
  return (
    <Text
      type={TextType.H1}
      {...props}
      isResponsive={props.isResponsive || true}
    >
      {props.children}
    </Text>
  );
};

export default Header1;
