export function log(argument: any, force?: boolean) {
  if (force) {
    /* eslint-disable no-console */
    console.log(argument);
  } else if (
    process.env.NODE_ENV === "development" &&
    process.env.logLevels === "info"
  ) {
    /* eslint-disable no-console */
    console.log(argument);
  }
}
