import { useState, useCallback } from "react";
import useIsomorphicLayoutEffect from "lib/use-isomorphic-layout-effect";

// typical breakpoints - frontend-client/styles/team-bumble/layout/layout.reference.scss
export enum MediaQueryWidth {
  "desktop-xlg" = 1440,
  "desktop-lg" = 1360,
  "desktop-md" = 1024,
  "desktop-sm" = 640,
  // depends on form__row mq 770
  "tablet-sm" = 769,
  "tablet-md" = 900,
  "tablet-lg" = 990,
  "phone-xlg" = 480,
  "phone-lg" = 412,
  "phone-md" = 376,
}

const useMediaQuery = (width = 900) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((event: MediaQueryListEvent) => {
    if (event.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    const media = global.window.matchMedia(`(max-width: ${width}px)`);
    media.addListener(updateTarget);

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true);
    }

    return () => media.removeListener(updateTarget);
  }, [width, updateTarget]);

  return targetReached;
};

export default useMediaQuery;
