import * as React from "react";
import Title from "./title";
import SubTitle from "./subtitle";
import Header1 from "./header-1";
import Header2 from "./header-2";
import Header3 from "./header-3";
import Header4 from "./header-4";
import P1 from "./p-1";
import P2 from "./p-2";
import P3 from "./p-3";
import Caption from "./caption";
import MobileCaption from "./mobile-caption";
import FormattedText from "./formatted-text";

export {
  Title,
  SubTitle,
  Header1,
  Header2,
  Header3,
  Header4,
  P1,
  P2,
  P3,
  Caption,
  MobileCaption,
  FormattedText,
};

/**
 * This is a mock component - to parse properly name in styleguide
 * @param props
 */
const Typography: React.FC<{ children?: React.ReactNode }> = (props) => (
  <>{props.children}</>
);

export default Typography;
