export default function throttle(func: any, wait: number) {
  let timeout: any;
  return function () {
    if (timeout) {
      return;
    }

    timeout = setTimeout(function () {
      timeout = undefined;
      func();
    }, wait);
  };
}
