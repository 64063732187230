import * as React from "react";
import { PageSEOPropsType } from "components/page-seo/page-seo";
import {
  Maybe,
  Post,
  RootQueryToCategoryConnection,
  Scalars,
} from "interfaces/bumble-bff-landing-microsite-cms";
// import PageSection from "components/page/page-section";
// import {
//   Title,
//   SubTitle,
//   // Header2, P1
// } from "components/typography";
import { SupportedLanguage } from "lib/lexemes/supported-languages";
import { PageCategoriesList } from "components/more-category-posts/more-category-posts";
import HeroImage from "components/hero-image/hero-image";
import useMediaQuery, { MediaQueryWidth } from "lib/use-media-query";
import { MultiPhotoSlider } from "components/Blocks/MultiPhotoSlider/MultiPhotoSlider";
import SuccessStories from "components/Blocks/SuccessStories/SuccessStories";
import { PageIndexCategoryList } from "components/page-index-category-list/page-index-category-list";
// import TextSection from "components/Blocks/TextSection/TextSection";
// import { Columns, ColumnsItem, ColumnsSize } from "components/columns";
// import { TextTagName } from "components/typography/text";
// import Button from "components/button/button";
// import { clickEventFromElement } from "components/google-analytics/google-analytics";
// import PageTemplateContentSvgContainer from "./page-template-svg-container";
// import { InView } from "react-intersection-observer";
// import { SocialSharingBlock } from "components/social-sharing-block/social-sharing-block";
// import { FeedGrid } from "components/feed-grid/feed-grid";
// import { useAppMarketUrl } from "lib/set-app-market-url";
// import FormEmailCapture from "components/form/form-email-capture";
// import lexemes from "components/lexemes/bff/lexemes";
// import { basePathIndexUrl } from "lib/schemes";
// import { setAssetPathWithLang } from "lib/updateImagePaths";
// import { useDeviceInfo } from "lib/use-device-info";
// import { AppstoreLinks } from "components/appstore-links/appstore-links";
// import IframeSection from "components/Blocks/IframeSection/IframeSection";

export enum PageTemplateContentType {
  Index = "Index",
  Archive = "Archive",
  Article = "Article",
}

type renderPageElementProps = {
  condition: boolean;
  children?: React.ReactNode;
};

export function renderPageElement(props: renderPageElementProps) {
  return props.condition ? props.children : null;
}

type PageTemplateContentProps = {
  id?: Scalars["Int"];
  lang: SupportedLanguage;
  type: PageTemplateContentType;
  intro?: React.ReactNode;
  categories?: Maybe<RootQueryToCategoryConnection>;
  pageCategoriesList?: PageCategoriesList;
  children?: React.ReactNode;
  modal?: React.ReactNode;
  title?: string;
  browserTab?: string;
  seoTitle?: string;
  seoDescription?: string;
  seoUrl?: string;
  openTitle?: string;
  openDescription?: string;
  datePublished?: string;
  dateModified?: string;
  // @TODO: add support in CMS
  metaKeywords?: string | null;
  metaRobotsNoindex?: string | null;
  metaRobotsNofollow?: string | null;
  opengraphPublishedTime?: string | null;
  opengraphModifiedTime?: string | null;
  openImageUrl?: string | null;
  openImageAlt?: string | null;
  heroImageUrl?: string | null;
  heroImageAlt?: string | null;
  heroImageHeight?: string | null;
  heroImageWidth?: string | null;
  openSiteName?: string;
  hasFooterCta?: boolean;
  footerTitleText?: Maybe<string> | undefined;
  hasFooterCtaButton?: boolean;
  ctaButtonText?: Maybe<string> | undefined;
  ctaButtonUrl?: Maybe<string> | undefined;
  ctaButtonTargetNewWindow?: boolean;
  onPageClick?: () => void;
  dynamicFooter?: boolean;
  geoIpCountry?: string | null;
  pageUrl?: string;
  articleUrl?: string;
  posts?: Maybe<Maybe<Post>[]> | undefined;
} & PageSEOPropsType;

const multiPhotoSliderProps = {
  name: "bumble-honey-ambassadors/multi-photo-slider",
  attributes: [
    {
      name: "title",
      value: "What Do Bumble Honeys Do?",
    },
    {
      name: "ctaText",
      value: "Learn More",
    },
    {
      name: "ctaLink",
      value: "/bumble-honey-ambassadors/about#what",
    },
    {
      name: "ctaTextTwo",
      value: "Apply Now",
    },
    {
      name: "ctaLinkTwo",
      value: "/bumble-honey-ambassadors/apply",
    },
  ],
  innerHTML: "\n\n\n\n\n\n",
  innerBlocks: [
    {
      name: "bumble-honey-ambassadors/multi-photo-slider-item",
      attributes: [
        {
          name: "mediaURL",
          value: "/bff/friendship/butterball-friendsgiving/events/Marissa.png",
        },
        {
          name: "title",
          value: "Marissa Meizz",
        },
      ],
    },
    {
      name: "bumble-honey-ambassadors/multi-photo-slider-item",
      attributes: [
        {
          name: "mediaURL",
          value: "/bff/friendship/butterball-friendsgiving/events/Anita.png",
        },
        {
          name: "title",
          value: "Anita Michaud",
        },
      ],
    },
    {
      name: "bumble-honey-ambassadors/multi-photo-slider-item",
      attributes: [
        {
          name: "mediaID",
          value: "32",
        },
        {
          name: "mediaURL",
          value: "/bff/friendship/butterball-friendsgiving/events/Danielle.png",
        },
        {
          name: "title",
          value: "Danielle Bayard Jackson",
        },
      ],
    },
    {
      name: "bumble-honey-ambassadors/multi-photo-slider-item",
      attributes: [
        {
          name: "mediaURL",
          value:
            "/bff/friendship/butterball-friendsgiving/events/ButterballsTTL.png",
        },
        {
          name: "title",
          value: "Butterball’s Turkey Talk-Line experts",
        },
      ],
    },
  ],
};
const successStoriesProps = {
  name: "bumble-success-stories/success-stories",
  attributes: [],
  innerHTML: "\n\u003cdiv\u003e\n\n\n\n\u003c/div\u003e\n",
  innerBlocks: [
    {
      name: "bumble-success-stories/success-story",
      attributes: [
        {
          name: "title",
          value: "#FindYourTable NYC Event",
        },
        {
          name: "subTitle",
          value: "Thursday, November 2",
        },
        {
          name: "text",
          value: `Enjoy hands-on demonstrations, tastings and more 
            to #FindYourTable
            <br /><br />
            Click the link below to RSVP and find out more.
            <br /><br />`,
        },
        {
          name: "ctaText",
          value: "Find out more",
        },
        {
          name: "clatLink",
          value: "https://findyourtable.splashthat.com",
        },
        {
          name: "ctaTarget",
          value: "_blank",
        },
        {
          name: "mediaID",
          value: "19",
        },
        {
          name: "mediaURL",
          value:
            "/bff/friendship/butterball-friendsgiving/news-items/news-item-01.png",
        },
      ],
      innerHTML:
        '\n\u003cdiv class="wp-block-bumble-success-stories-success-story"\u003e\u003c/div\u003e\n',
      innerBlocks: [],
    },
    {
      name: "bumble-success-stories/success-story",
      attributes: [
        {
          name: "title",
          value: "Learn to Turkey Virtual Event #1",
        },
        {
          name: "subTitle",
          value: "Thursday, November 9",
        },
        {
          name: "text",
          value: `Thinking of hosting? Learn how easy it is to roast a turkey and tips for putting your own stamp on the occasion!
            <br /><br />
            Click the link below to RSVP and find out more.
            <br /><br />`,
        },
        {
          name: "ctaText",
          value: "Find out more",
        },
        {
          name: "clatLink",
          value: "https://learntoturkeyvirtualevent1forf.splashthat.com",
        },
        {
          name: "ctaTarget",
          value: "_blank",
        },
        {
          name: "mediaID",
          value: "25",
        },
        {
          name: "mediaURL",
          value:
            "/bff/friendship/butterball-friendsgiving/news-items/news-item-02.png",
        },
      ],
      innerHTML:
        '\n\u003cdiv class="wp-block-bumble-success-stories-success-story"\u003e\u003c/div\u003e\n',
      innerBlocks: [],
    },
    {
      name: "bumble-success-stories/success-story",
      attributes: [
        {
          name: "title",
          value: "Learn to Turkey Virtual Event #2",
        },
        {
          name: "subTitle",
          value: "Wednesday, November 15",
        },
        {
          name: "text",
          value: `
            Missed our first Learn to Turkey event? Now’s your chance!
            <br /><br />
            Click the link below to RSVP and find out more.
            <br /><br />
            `,
        },
        {
          name: "ctaText",
          value: "Find out more",
        },
        {
          name: "clatLink",
          value: "https://learntoturkeyvirtualevent2forf.splashthat.com",
        },
        {
          name: "ctaTarget",
          value: "_blank",
        },
        {
          name: "mediaID",
          value: "27",
        },
        {
          name: "mediaURL",
          value:
            "/bff/friendship/butterball-friendsgiving/news-items/news-item-03.png",
        },
      ],
      innerHTML:
        '\n\u003cdiv class="wp-block-bumble-success-stories-success-story"\u003e\u003c/div\u003e\n',
      innerBlocks: [],
    },
  ],
};
const pageCategoriesList = {
  edges: [
    {
      node: {
        name: "Butterball",
        postCategoryFieldGroup: {
          order: 1,
          backgroundcolor: "#FFE9AD",
          ctaText: "More articles",
          ctaUrl: null,
          showonmainpage: true,
        },
        posts: {
          edges: [
            {
              node: {
                databaseId: 19,
                title: "How to Choose a Turkey",
                uri: "https://www.butterball.com/how-to/choose-a-turkey",
                excerpt:
                  "Fresh or frozen? How large? Select the right turkey for your Friendsgiving by considering these tips.",
                featuredImage: {
                  node: {
                    altText: "",
                    mediaItemUrl:
                      "/bff/friendship/butterball-friendsgiving/posts/buttertop-01.png",
                    mimeType: "image/png",
                  },
                },
                postFieldGroup: {
                  ctaText: "Read more",
                  ctaUrl: null,
                },
              },
            },
            {
              node: {
                databaseId: 26,
                title: "How to Thaw a Frozen Turkey",
                uri: "https://www.butterball.com/how-to/thaw-a-turkey",
                excerpt:
                  "Safely thawing your frozen turkey is one of the most important but often overlooked steps in holiday prep. Watch an expert demonstrate Butterball’s tried-and-true thawing methods.",
                featuredImage: {
                  node: {
                    altText: "",
                    mediaItemUrl:
                      "/bff/friendship/butterball-friendsgiving/posts/buttertop-02.png",
                    mimeType: "image/png",
                  },
                },
                postFieldGroup: {
                  ctaText: "Read more",
                  ctaUrl: null,
                },
              },
            },
            {
              node: {
                databaseId: 31,
                title: "How to Roast a Turkey",
                uri: "https://www.butterball.com/how-to/cook-a-turkey/roast",
                excerpt:
                  "Roasting a whole turkey is easier than you think! Just follow these simple instructions for a delicious Friendsgiving centerpiece.",
                featuredImage: {
                  node: {
                    altText: "",
                    mediaItemUrl:
                      "/bff/friendship/butterball-friendsgiving/posts/buttertop-03.png",
                    mimeType: "image/png",
                  },
                },
                postFieldGroup: {
                  ctaText: "Read more",
                  ctaUrl: null,
                },
              },
            },
            {
              node: {
                databaseId: 23,
                title: "How to Reach the Experts for Help",
                uri: "https://www.butterball.com/how-to",
                excerpt:
                  "The Butterball Turkey Talk-Line® has supported millions of holiday hosts since it launched in 1981. You can reach an expert Nov. 1 through Dec. 24 by calling 1-800-BUTTERBALL, texting 844-877-3456, or via Butterball.com or&nbsp;social.",
                featuredImage: {
                  node: {
                    altText: "",
                    mediaItemUrl:
                      "/bff/friendship/butterball-friendsgiving/posts/buttertop-04.png",
                    mimeType: "image/png",
                  },
                },
              },
            },
          ],
        },
      },
    },
    {
      node: {
        name: "Buzz",
        postCategoryFieldGroup: {
          order: 2,
          backgroundcolor: "#FFDE3B",
          ctaText: "More articles",
          ctaUrl: null,
          showonmainpage: true,
        },
        posts: {
          edges: [
            {
              node: {
                databaseId: 19,
                title: "How To Make New Friends Online",
                uri: "https://bumble-buzz.com/how-to-make-friends-online/",
                excerpt:
                  "If you can find “the one” by swiping right, why not try finding your ride or die the same way? We share three easy ways to make friends on Bumble BFF.",
                featuredImage: {
                  node: {
                    altText: "",
                    mediaItemUrl:
                      "/bff/friendship/butterball-friendsgiving/posts/buzz-01.png",
                    mimeType: "image/png",
                  },
                },
                postFieldGroup: {
                  ctaText: "Read more",
                  ctaUrl: null,
                },
              },
            },
            {
              node: {
                databaseId: 26,
                title: "How to Turn a Casual Friend into a Best Friend",
                uri: "https://bumble-buzz.com/how-to-make-a-best-friend/",
                excerpt:
                  "While most people don’t become best friends overnight, there are ways you can intentionally deepen your more casual friendships to build a more meaningful connection.",
                featuredImage: {
                  node: {
                    altText: "",
                    mediaItemUrl:
                      "/bff/friendship/butterball-friendsgiving/posts/buzz-02.png",
                    mimeType: "image/png",
                  },
                },
                postFieldGroup: {
                  ctaText: "Read more",
                  ctaUrl: null,
                },
              },
            },
            {
              node: {
                databaseId: 31,
                title: "How to Start a Conversation on Bumble For Friends",
                uri: "https://bumble-buzz.com/bumble-bff-conversation/",
                excerpt:
                  "Making friends on Bumble For Friends isn’t quite the same as making friends at college or at work. But even without a mutual connection to introduce you or a shared setting to help...",
                featuredImage: {
                  node: {
                    altText: "",
                    mediaItemUrl:
                      "/bff/friendship/butterball-friendsgiving/posts/buzz-03.png",
                    mimeType: "image/png",
                  },
                },
                postFieldGroup: {
                  ctaText: "Read more",
                  ctaUrl: null,
                },
              },
            },
            {
              node: {
                databaseId: 23,
                title: "How to Make Friends After College",
                uri: "https://bumble.com/bff/friendship/friends-after-college",
                excerpt:
                  "Finding new friends in new cities and a new life stage, where your lives aren’t as structured or as confined to one location, can be tricky and can leave you feeling lonely or isolated.",
                featuredImage: {
                  node: {
                    altText: "",
                    mediaItemUrl:
                      "/bff/friendship/butterball-friendsgiving/posts/buzz-04.png",
                    mimeType: "image/png",
                  },
                },
                postFieldGroup: {
                  ctaText: "Read more",
                  ctaUrl: null,
                },
              },
            },
          ],
        },
      },
    },
  ],
};

function PageTemplateContent(props: PageTemplateContentProps) {
  const isBreakpoint = useMediaQuery(MediaQueryWidth["tablet-md"]);

  return (
    <>
      <HeroImage
        // @TODO: add lexeme
        alt=""
        aspectRatio={isBreakpoint ? 1788 / 2862 : 2048 / 1200}
        objectFit="contain"
        src={
          isBreakpoint
            ? "/bff/friendship/butterball-friendsgiving/header-mobile.jpg"
            : "/bff/friendship/butterball-friendsgiving/header.jpg"
        }
        priority={true}
      />

      <section
        className="page-section page-section--splash-screen benefit-section page__section--padded"
        style={{ backgroundColor: "#FFE9AD" }}
      >
        <div className="page-section__inner">
          <header className="benefit-section__header">
            <div className="h2 is-responsive">
              Friendsgiving is almost here!
            </div>
          </header>
          <div
            className="cta-box__title"
            style={{
              margin: "25px auto 0",
              textAlign: "center",
              maxWidth: 735,
            }}
          >
            <div className="p-1">
              We’ve partnered up with Butterball to help you #FindYourTable
              amongst new friends this holiday szn to turkey together.
            </div>
          </div>
        </div>
      </section>

      <MultiPhotoSlider title="Special guests" block={multiPhotoSliderProps} />

      <section
        className="page-section page-section--splash-screen benefit-section page__section--padded"
        style={{
          backgroundColor: "rgb(255, 252, 244)",
          marginTop: "calc(-1 * var(--content-section-padding-vertical))",
          paddingTop: "calc(-1 * var(--content-section-padding-vertical))",
        }}
      >
        <div className="page-section__inner">
          <div
            className="cta-box__title"
            style={{
              margin: "0 auto 0",
              textAlign: "center",
              maxWidth: 735,
            }}
          >
            <div className="p-1">
              Our IRL and virtual events will be hosted by special guests:
              Danielle Bayard Jackson, Marissa Meizz, Anita Michaud, and
              Butterball’s Turkey Talk-Line experts. They&apos;ll be helping you
              turn this Friendsgiving into a special one to remember! So, what
              are you waiting for?
            </div>
          </div>
        </div>
      </section>

      {props.children}

      <section
        className="page-section page-section--splash-screen benefit-section page__section--padded"
        style={{
          backgroundColor: "rgb(255, 252, 244)",
          marginTop: 0,
        }}
      >
        <div className="page-section__inner">
          <header className="benefit-section__header">
            <div className="h2 is-responsive">
              #FindYourTable at <br /> one of our fun events.
            </div>
          </header>
        </div>

        {/** @ts-ignore */}
        <SuccessStories block={successStoriesProps} />
      </section>

      <section
        className="page-section page-section--splash-screen benefit-section page__section--padded"
        style={{
          backgroundColor: "#FFDE3B",
          marginTop: 0,
        }}
      >
        <div className="page-section__inner">
          <header className="benefit-section__header">
            <div className="h2 is-responsive">Friendship & Turkey Tips</div>
          </header>
          <div
            className="cta-box__title"
            style={{
              margin: "25px auto 0",
              textAlign: "center",
              maxWidth: 735,
            }}
          >
            <div className="p-1">
              We value friendship, togetherness and great turkey-roasting
              skills, so here are some resources to help you out this holiday
              season!
            </div>
          </div>
        </div>
      </section>

      <section
        className="page-section"
        style={{
          //   backgroundColor: "#FFDE3B",
          marginTop: 0,
        }}
      >
        {/** @ts-ignore */}
        <PageIndexCategoryList pageCategoriesList={pageCategoriesList} />
      </section>

      <section
        className="page-section page-section--splash-screen benefit-section page__section--padded no-margin-top"
        style={{ backgroundColor: "rgb(255,252,244)", marginTop: 0 }}
      >
        <div className="no-bg">
          <HeroImage
            // @TODO: add lexeme
            alt=""
            aspectRatio={isBreakpoint ? 1788 / 722 : 2878 / 460}
            objectFit="contain"
            src={
              isBreakpoint
                ? "/bff/friendship/butterball-friendsgiving/footer-image-mobile.png"
                : "/bff/friendship/butterball-friendsgiving/footer-image.png"
            }
          />
        </div>
      </section>

      <div className="no-bg no-margin-top">
        <HeroImage
          // @TODO: add lexeme
          alt=""
          aspectRatio={isBreakpoint ? 1788 / 722 : 2878 / 460}
          objectFit="cover"
          src={
            isBreakpoint
              ? "/bff/friendship/butterball-friendsgiving/footer-mobile.png"
              : "/bff/friendship/butterball-friendsgiving/footer.png"
          }
        />
      </div>
    </>
  );
}
PageTemplateContent.type = PageTemplateContentType;

export default PageTemplateContent;
