import * as React from "react";
import cx from "classnames";

export enum CtaBoxCaptionAlign {
  CENTERED = "CENTERED",
  NONE = "NONE",
}

const mapAlignToClasses = {
  [CtaBoxCaptionAlign.CENTERED]: "cta-box__caption--centered",
  [CtaBoxCaptionAlign.NONE]: "",
};

type CtaBoxCaptionProps = {
  align?: CtaBoxCaptionAlign;
  children?: React.ReactChild | React.ReactChild[];
  marginTop?: React.CSSProperties["marginTop"];
};

const CtaBoxCaption: React.FC<CtaBoxCaptionProps> = ({
  children,
  marginTop,
  align = CtaBoxCaptionAlign.NONE,
}) => {
  const classname = cx({
    "cta-box__caption": true,
    [mapAlignToClasses[align]]: true,
  });
  return (
    <div className={classname} style={{ marginTop }}>
      {children}
    </div>
  );
};

export default CtaBoxCaption;
