import { ContentBlock } from "interfaces/bumble-bff-landing-microsite-cms";

export function setBlocksPropsFromAttributes(
  attributes: ContentBlock["attributes"],
) {
  return (
    attributes &&
    Object.fromEntries(attributes.map((item: any) => [item.name, item.value]))
  );
}

export function setGlideSelector(selector: string | HTMLDivElement | null) {
  return selector || ".glide";
}
