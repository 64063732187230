import * as React from "react";
import cx from "classnames";
// H5 instead of first P1
import { Header3, P1 } from "components/typography";
import Button from "components/button/button";
import { Columns, ColumnsItem } from "components/columns";
import MediaBox, { MediaBoxAspect } from "components/media-box/media-box";
import CtaBox from "components/cta-box/cta-box";
import CtaBoxTitle, {
  CtaBoxTitleAlign,
} from "components/cta-box/cta-box-title";
import CtaBoxSubtitle, {
  CtaBoxSubTitleAlign,
} from "components/cta-box/cta-box-subtitle";
import CtaBoxCaption from "components/cta-box/cta-box-caption";
import useMediaQuery, { MediaQueryWidth } from "lib/use-media-query";
import { clickEventFromElement } from "components/google-analytics/google-analytics";

type SuccessStoryProps = {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  text?: React.ReactNode;
  ctaText?: React.ReactNode;
  ctaLink?: string;
  ctaTarget?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"];
  mediaURL?: string;
  imageAlt?: string;
};

function SuccessStory(props: SuccessStoryProps) {
  const isBreakpoint = useMediaQuery(MediaQueryWidth["tablet-md"]);

  const className = {
    block: cx({
      "success-story": true,
    }),
    media: cx({
      "success-story__media": true,
    }),
    image: cx({
      "success-story__image": true,
      "success-story__image--full": true,
    }),
  };

  return (
    <div className={className.block}>
      <Columns hasMobileLayout={true} gap={isBreakpoint ? 16 : 24}>
        {props.mediaURL ? (
          <ColumnsItem size={ColumnsItem.size["1/1"]}>
            <div className={className.media}>
              <div className={className.image}>
                <MediaBox aspect={MediaBoxAspect.FREE} src={props.mediaURL} />
              </div>
            </div>
          </ColumnsItem>
        ) : null}
        <ColumnsItem size={ColumnsItem.size["1/1"]}>
          <CtaBox>
            <>
              {props.title ? (
                <CtaBoxTitle
                  align={isBreakpoint ? CtaBoxTitleAlign.CENTERED : undefined}
                >
                  <Header3>{props.title}</Header3>
                </CtaBoxTitle>
              ) : null}
            </>

            <>
              {props.subTitle ? (
                <CtaBoxSubtitle
                  align={
                    isBreakpoint ? CtaBoxSubTitleAlign.CENTERED : undefined
                  }
                  marginTop={isBreakpoint ? 8 : 16}
                >
                  <P1>
                    <i>{props.subTitle}</i>
                  </P1>
                </CtaBoxSubtitle>
              ) : null}

              {props.text ? (
                <CtaBoxCaption marginTop={isBreakpoint ? 8 : 16}>
                  {/** @ts-ignore */}
                  <P1 html={props.text} />
                </CtaBoxCaption>
              ) : null}

              {props.ctaLink && props.ctaText ? (
                <CtaBoxCaption marginTop={isBreakpoint ? 8 : 16}>
                  <Button
                    designType={Button.designType.READ_MORE}
                    text={props.ctaText}
                    href={props.ctaLink}
                    target={props.ctaTarget}
                    seo={{
                      category: "Navigation",
                      action: "Button Click",
                      label: `${props.ctaText} - ${props.ctaLink}`,
                    }}
                    onClick={(event) => clickEventFromElement(event)}
                  />
                </CtaBoxCaption>
              ) : null}
            </>
          </CtaBox>
        </ColumnsItem>
      </Columns>
    </div>
  );
}

export default SuccessStory;
