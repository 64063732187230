import * as React from "react";
import Text, { TextType, TextProps } from "components/typography/text";

const Header2: React.FC<TextProps> = (props) => {
  return (
    <Text
      type={TextType.H2}
      {...props}
      isResponsive={props.isResponsive || true}
    >
      {props.children}
    </Text>
  );
};

export default Header2;
