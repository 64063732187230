import React, { useEffect, useState } from "react";
import cx from "classnames";
import Image, { ImageProps } from "lib/image-container";
import throttle from "lib/throttle";
import { animated } from "@react-spring/web";
import { useAmp } from "next/amp";
import { convertToNextImage } from "lib/updateImagePaths";

export type HeroImageProps = {
  alt?: string | undefined;
  src: string;
  aspectRatio?: number;
  overlay?: React.ReactNode;
  hasParallax?: boolean;
  priority?: boolean;
  height?: number;
  width?: number;
  objectFit?: ImageProps["objectFit"];
  id?: string;
  hasAspect?: boolean;
};

const HeroImage: React.FC<HeroImageProps> = (props) => {
  const {
    alt,
    src,
    aspectRatio = 1440 / 700,
    overlay,
    // hasParallax,
    objectFit,
    id,
    hasAspect,
  } = props;
  // const [overlap, setOverlap] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  // parameters of container - position and height
  const [offsets, setOffsets] = React.useState<{
    offsetTop: number;
    offsetHeight: number;
  } | null>(null);
  const isAmp = useAmp();
  const className = {
    block: cx({
      "hero-image": true,
      "hero-image--amp": isAmp,
      "hero-image--aspect-ratio": hasAspect,
    }),
  };

  // block container
  const elementRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isAmp) {
      const { current } = elementRef;

      // check and remember window height for further calculations (performance improvement)
      if (windowHeight === 0) {
        setWindowHeight(global.window.innerHeight);
      }

      // check sizes for the hero image
      if (current && offsets === null) {
        // check and remember block position for further calculations (performance improvement)
        // timeout is necessary to render block css and calculate position properly
        setTimeout(() => {
          setOffsets({
            offsetTop: current.getBoundingClientRect().top,
            offsetHeight: current.offsetHeight,
          });
        }, 300);
      }

      // detect and set scroll position
      const handleScroll = () => {
        if (offsets && windowHeight) {
          const scrollTop = global.window.scrollY;

          // the block animation should be process during time
          // when the block is travelling through the screen
          // overlapPercentage shows progress of this travel
          let overlapPercentage = 0;

          // for cases when the hero image is visible initially we set overlap percentage to 0
          // to let users see the top of the image
          if (offsets.offsetTop < windowHeight) {
            overlapPercentage =
              scrollTop / (offsets.offsetHeight + offsets.offsetTop);
          } else {
            overlapPercentage =
              (scrollTop - offsets.offsetTop) /
              (offsets.offsetHeight + windowHeight);
          }

          if (overlapPercentage < 0) {
            overlapPercentage = 0;
          } else if (overlapPercentage > 1) {
            overlapPercentage = 1;
          }

          // setOverlap(overlapPercentage);
        }
      };

      global.window.addEventListener("scroll", throttle(handleScroll, 30));
      return () =>
        global.window.removeEventListener("scroll", throttle(handleScroll, 30));
    } else {
      return () => undefined;
    }
  }, [offsets, windowHeight, isAmp]);

  // const [{ springOverlap }, springSetOverlap] = useSpring(() => ({
  //     springOverlap: 0,
  // }));

  // springSetOverlap({ springOverlap: overlap });

  // const backgroundSlide = 150;
  // const shiftSpeedCoefficient = 1.1; // background image slides faster to let a user check it whole
  // const mediaStyles = springOverlap.interpolate((strOverlap) => {
  //     const shift = Math.min(
  //         backgroundSlide,
  //         (strOverlap as number) * shiftSpeedCoefficient * backgroundSlide
  //     );
  //     return `translateY(${-shift}px)`;
  // });
  const imageElement = isAmp ? (
    <div className="hero-image__media">
      <amp-img
        on="tap:lightbox1"
        role="button"
        tabindex="0"
        className="contain"
        title={alt}
        layout="fill"
        width={props.width || 1200}
        height={props.height || 750}
        src={convertToNextImage(src)}
      ></amp-img>
    </div>
  ) : (
    <animated.div
      className="hero-image__media"
      // style={hasParallax ? { transform: mediaStyles, bottom: -backgroundSlide } : undefined}
    >
      <Image
        title={alt}
        alt={alt}
        src={src}
        objectFit={objectFit || "cover"}
        layout="fill"
        loading={props.priority ? "eager" : "lazy"}
        priority={props.priority}
        id={id || undefined}
      />
    </animated.div>
  );

  // TODO: review width and height behaviour
  return (
    <div
      className={className.block}
      style={{
        ["--aspect-ratio" as string]: aspectRatio,
      }}
      ref={elementRef}
    >
      {imageElement}
      {overlay ? <div className="hero-image__overlay">{overlay}</div> : null}
    </div>
  );
};

export default HeroImage;
