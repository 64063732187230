import React from "react";
import Glide from "@glidejs/glide";
import { setGlideSelector } from "components/Blocks/utils";
import { MultiPhotoSlide } from "components/Blocks/MultiPhotoSlider/MultiPhotoSlide";

const defaultPeek = 0;
const peek = {
  /* eslint-disable no-nested-ternary */
  before: !isNaN(defaultPeek)
    ? defaultPeek
    : window.innerWidth < 600
    ? 86
    : window.innerWidth < 1000
    ? 240
    : window.innerWidth < 1200
    ? 90
    : 325,
  after: 0,
};
const defaultOptions = {
  type: "carousel",
  focusAt: "center",
  gap: 24,
  peek,
  throttle: 300,
} as Glide.Options;

type MultiPhotoSliderProps = {
  peek?: string;
  time?: string;
  block?: any;
  title?: string;
  perView?: string;
  pauseOnHover?: string;
  ctaText?: string;
  ctaLink?: string;
  ctaTextTwo?: string;
  ctaLinkTwo?: string;
};

function MultiPhotoSlider(props: MultiPhotoSliderProps) {
  const blocks = props.block.innerBlocks.map((block: any) =>
    Object.fromEntries(
      block.attributes.map((item: any) => [item.name, item.value]),
    ),
  );
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const glideRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    let glides: Glide;
    let paused: boolean;
    let index: number;
    const defaultPerView = 0;
    const defaultTime = 0;
    const pauseOnHover = false;

    function mountSlides() {
      //   const perViewWidth = Math.max(Math.floor(window.innerWidth / 650), 2);
      const perViewWidth = 1;

      const perView =
        defaultPerView ||
        (window.innerWidth < 1000
          ? 1
          : window.innerWidth < 1200
          ? 2
          : perViewWidth);

      if (wrapperRef.current !== null) {
        wrapperRef.current.style.opacity = "1";

        glides = new Glide(setGlideSelector(glideRef.current), {
          ...defaultOptions,
          startAt: index ?? 0,
          autoplay: paused ? false : defaultTime || 4000,
          hoverpause: true || pauseOnHover,
          perView,
          cloningRatio: 1,
        });

        glides.mount();

        const selector = glides.selector as unknown as HTMLDivElement;

        glides.on("move", () => {
          selector.classList.add("glide__moving");
        });
        glides.on("move.after", () => {
          setTimeout(() => {
            if (!selector.classList.contains("glide--dragging")) {
              selector.classList.remove("glide__moving");
            }
            index = glides.index;
          }, 50);
        });

        glides.on("swipe.end", () => {
          setTimeout(() => {
            glides.pause();
            paused = true;
          }, 1000);
        });
      } else {
        setTimeout(mountSlides, 200);
      }
    }

    mountSlides();

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          glides.enable();
          glides.play();
          // @ts-ignore
          glides._c.Autoplay.start();
        } else {
          glides.pause();
          glides.disable();
          // @ts-ignore
          glides._c.Autoplay.stop();
        }
      });
    });

    observer.observe(glideRef.current!);

    return () => {
      glides.destroy();
      observer.disconnect();
    };
  }, []);

  return (
    <div
      className="wrapper white-bg black flex h-center v-center what"
      ref={wrapperRef}
      style={{ backgroundColor: "rgb(255,252,244)" }}
    >
      <div className="content">
        {props.title ? (
          <div className="black weight-black center-align beehive-h1">
            {props.title}
          </div>
        ) : null}

        <div className="wrapper">
          <div className="glide" ref={glideRef}>
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">
                {blocks.map((block: any, index: number) => (
                  <MultiPhotoSlide
                    key={index}
                    index={index}
                    mediaURL={block.mediaURL}
                    title={block.title}
                    ctaLink={block.ctaLink}
                  />
                ))}
              </ul>
            </div>
            <div className="glide__controls">
              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className="glide__arrow glide__arrow--left"
                  data-glide-dir="<"
                >
                  <svg
                    width="17"
                    height="26"
                    viewBox="0 0 17 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 22L4 13L13 4"
                      stroke="rgb(248,233,190)"
                      strokeWidth="8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div className="glide__bullets" data-glide-el="controls[nav]">
                {blocks.map((_block: any, index: number) => (
                  <button
                    className="glide__bullet"
                    data-glide-dir={`=${index}`}
                    key={index}
                  ></button>
                ))}
              </div>

              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className="glide__arrow glide__arrow--right"
                  data-glide-dir=">"
                >
                  <svg
                    width="17"
                    height="26"
                    viewBox="0 0 17 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 4L13 13L4 22"
                      stroke="rgb(248,233,190)"
                      strokeWidth="8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="do-ctas flex h-center">
          {props.ctaText && props.ctaLink ? (
            <a className="weight-bold" href={props.ctaLink}>
              {props.ctaText}
            </a>
          ) : null}
          {props.ctaTextTwo && props.ctaLinkTwo ? (
            <a className="weight-bold" href={props.ctaLinkTwo}>
              {props.ctaTextTwo}
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export { MultiPhotoSlider };
