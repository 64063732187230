import * as React from "react";
import cx from "classnames";
import { socialNetworkLinks } from "components/social-links/social-network-links";

type Props = {
  logo?: React.ReactNode;
  isActive?: boolean;
  closeAction?: React.ReactNode;
  menuList?: React.ReactNode[];
  downloadAction?: React.ReactNode;
  jsClass?: string;
  jsBackDropClass?: string;
  closePanel?: (...args: any[]) => any;
};

/**
 * Make screen width < 1024 px in order to see the component
 */
function MobilePanel(props: Props) {
  const {
    logo,
    isActive,
    closeAction,
    menuList,
    downloadAction,
    closePanel,
    jsClass = "js-mobile-navigation",
  } = props;

  const className = {
    block: cx({
      "mobile-panel": true,
      "is-active": isActive,
      [jsClass]: jsClass,
    }),
    backdrop: cx({
      "mobile-panel__backdrop": true,
    }),
  };

  return (
    <div className={className.block}>
      <div className="mobile-panel__inner">
        <div className="mobile-panel__header">
          {logo ? <div className="mobile-panel__logo">{logo}</div> : null}
          <div className="mobile-panel__close">{closeAction}</div>
        </div>

        {menuList ? (
          <div className="mobile-panel__content">
            {menuList.map((menu, index) => (
              <div
                className="mobile-panel__content-menu"
                key={`mobile-panel-content-menu-${index}`}
              >
                {menu}
              </div>
            ))}
          </div>
        ) : null}

        <div className="mobile-panel__download-app">{downloadAction}</div>

        <div className="mobile-panel__socials" id="footer">
          <div className="flex social-icons md:h-center">
            <a
              href={socialNetworkLinks.TikTok}
              target="_blank"
              rel="noreferrer"
            >
              <svg>
                <use xlinkHref="#tiktok" />
              </svg>
              <svg>
                <use xlinkHref="#tiktok-hover" />
              </svg>
            </a>
            <a
              href={socialNetworkLinks.Twitter}
              target="_blank"
              rel="noreferrer"
            >
              <svg>
                <use xlinkHref="#twitter" />
              </svg>
              <svg>
                <use xlinkHref="#twitter-hover" />
              </svg>
            </a>
            <a
              href={socialNetworkLinks.Instagram}
              target="_blank"
              rel="noreferrer"
            >
              <svg>
                <use xlinkHref="#instagram" />
              </svg>
              <svg>
                <use xlinkHref="#instagram-hover" />
              </svg>
            </a>
            <a
              href={socialNetworkLinks.Facebook}
              target="_blank"
              rel="noreferrer"
            >
              <svg>
                <use xlinkHref="#facebook" />
              </svg>
              <svg>
                <use xlinkHref="#facebook-hover" />
              </svg>
            </a>
            <a
              href={socialNetworkLinks.YouTube}
              target="_blank"
              rel="noreferrer"
            >
              <svg>
                <use xlinkHref="#youtube" />
              </svg>
              <svg>
                <use xlinkHref="#youtube-hover" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className={className.backdrop} onClick={closePanel}></div>
    </div>
  );
}

export default MobilePanel;
