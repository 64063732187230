import * as React from "react";
import { NextSeo } from "next-seo";
import { baseUrl } from "lib/base-url";
import supportedLanguages, {
  SupportedLanguage,
} from "lib/lexemes/supported-languages";
import { cleanUrlFromParams } from "lib/set-url-params";
import { basePathIndexUrl } from "lib/schemes";
import lexemes from "components/lexemes/bff/lexemes";

/**
 * Returns "alternate" languages for SEO
 */
function getLanguageAlternates() {
  // @TODO add alt
  // const lang = 'en-us';

  return supportedLanguages.map((supportedLanguage) => {
    return {
      hrefLang: supportedLanguage,
      href: `https://bumble.com/${supportedLanguage}${basePathIndexUrl}`,
    };
  });
}

function getCanonicalUrl(url: string, lang: string) {
  let canonicalUrl = lang
    ? cleanUrlFromParams(url).replace(`/${lang}`, "").replace(/\/$/g, "")
    : cleanUrlFromParams(url).replace(/\/$/g, "");

  if (canonicalUrl.endsWith(`${basePathIndexUrl}${basePathIndexUrl}`)) {
    canonicalUrl = canonicalUrl.replace(basePathIndexUrl, "");
  }

  return canonicalUrl;
}

const setAbsPathForImageUrl = (imageUrl: string) => {
  return imageUrl.replace(
    "http://cdn-internal:8081/bumble-bff-landing-production/",
    `${baseUrl}/bff/cdn-image/`,
  );
};
const SEO = {
  get TAB_TITLE() {
    return lexemes.lxbffLandingSEOTitle;
  },
  get NAME() {
    return lexemes.lxbffLandingSEOTitle;
  },
  get TITLE() {
    return lexemes.lxbffLandingSEOTitle;
  },
  get DESCRIPTION() {
    return lexemes.lxbffLandingSEODescription;
  },
  URL: "https://bumble.com/bff",
  IMAGE_URL: "https://bumble.com/bff/assets/social-sharing.jpg",
  get IMAGE_ALT() {
    return lexemes.lxbffLandingSEOTitle;
  },
};

export type PageSEOPropsType = {
  lang: SupportedLanguage;
  browserTab?: string;
  seoTitle?: string;
  seoDescription?: string;
  seoUrl?: string;
  openTitle?: string;
  openDescription?: string;
  openImageUrl?: string | null;
  openImageAlt?: string | null;
  openImageHeight?: string | null;
  openImageWidth?: string | null;
  openSiteName?: string;
  metaRobotsNoindex?: string | null;
  metaRobotsNofollow?: string | null;
};

const PageSEO = (props: PageSEOPropsType) => {
  const canonicalUrl = getCanonicalUrl(props.seoUrl || SEO.URL, props.lang);
  const imageUrl = props.openImageUrl
    ? setAbsPathForImageUrl(props.openImageUrl)
    : SEO.IMAGE_URL;
  const imageAlt = props.openImageAlt || props.browserTab || SEO.IMAGE_ALT;
  const pageTitle = props.browserTab || SEO.TAB_TITLE;
  const pageDescriotion = props.seoDescription || SEO.DESCRIPTION;
  const siteName = props.openSiteName || props.browserTab || SEO.NAME;

  return (
    <NextSeo
      noindex={props.metaRobotsNoindex === "noindex"}
      nofollow={props.metaRobotsNofollow === "nofollow"}
      title={pageTitle}
      description={pageDescriotion}
      canonical={canonicalUrl}
      languageAlternates={getLanguageAlternates()}
      openGraph={{
        url: canonicalUrl,
        locale: props.lang,
        title: props.openTitle ? props.openTitle : pageTitle,
        description: props.openDescription
          ? props.openDescription
          : pageDescriotion,
        images: [
          {
            url: imageUrl,
            alt: imageAlt,
            width: 1200,
            height: 630,
          },
        ],
        site_name: siteName,
      }}
      twitter={{
        handle: "@bumble",
        site: "@bumble",
        cardType: "summary_large_image",
      }}
      facebook={{
        appId: "428250913904849",
      }}
      additionalMetaTags={[
        {
          name: "apple-itunes-app",
          // @TODO add SEO for BUmbleBFF
          content: "app-id=930441707",
        },
        {
          property: "p:domain_verify",
          content: "26cd87d4b7db08a05bdfa2219d3671b6",
        },
        {
          property: "referrer",
          content: "origin-when-cross-origin",
        },
        {
          property: "twitter:app:name:iphone",
          content: "Bumble - Changing the rules of the game.",
        },
        {
          property: "twitter:app:id:iphone",
          content: "930441707",
        },
        {
          property: "keywords",
          content: "friendsgiving, butterball, turkey tips, friendship tips",
        },
      ]}
    />
  );
};

export default PageSEO;
