import * as React from "react";
import Text, { TextType, TextProps } from "components/typography/text";

const P1: React.FC<TextProps> = (props) => (
  <Text type={TextType.P1} isMedium={props.isMedium} {...props}>
    {props.children}
  </Text>
);

export default P1;
