import * as React from "react";
import cx from "classnames";
import throttle from "lib/throttle";

export enum ColumnsSize {
  "1/1",
  "1/3",
  "2/3",
}

const mapSizeToClasses = {
  [ColumnsSize["1/1"]]: "columns__item",
  [ColumnsSize["1/3"]]: "columns__item--one-third",
  [ColumnsSize["2/3"]]: "columns__item--two-third",
};

type ColumnsPropsType = {
  children?: React.ReactNode;
  size?: ColumnsSize;
  marginLeft?: number | string;
  justifyContentCenter?: boolean;
  isSticky?: boolean;
  reference?: React.RefObject<HTMLDivElement>;
  addStickyHeaderGap?: boolean;
  limitedWidth?: boolean;
  width?: number | string;
  extraClass?: string;
};

function ColumnsItem(props: ColumnsPropsType) {
  const {
    children,
    size = ColumnsSize["1/3"],
    marginLeft,
    justifyContentCenter,
    isSticky,
    reference,
    addStickyHeaderGap,
    limitedWidth,
    width,
    extraClass,
  } = props;
  const classname = cx({
    columns__item: true,
    [mapSizeToClasses[size]]: true,
    "columns__item--center": justifyContentCenter,
    "columns__item--within": limitedWidth,
    "is-sticky": isSticky,
    [extraClass as string]: extraClass,
  });

  React.useEffect(() => {
    function update() {
      const refPosition = reference?.current?.getBoundingClientRect().y;
      const hasScrollreachedElement = refPosition && refPosition < 0;

      if (hasScrollreachedElement || addStickyHeaderGap) {
        global.document.body.classList.add("is-initiated");
      } else {
        global.document.body.classList.remove("is-initiated");
      }
    }

    if (reference) {
      const ieBrowser =
        global.document.body.classList.contains("ie11") ||
        global.document.body.classList.contains("edge");

      const throttledUpdate = throttle(update, 300);

      if (ieBrowser === false) {
        global.window.addEventListener("scroll", throttledUpdate);
        global.window.addEventListener("resize", throttledUpdate);
      }

      return () => {
        if (ieBrowser === false) {
          global.window.removeEventListener("scroll", throttledUpdate);
          global.window.addEventListener("resize", throttledUpdate);
        }
      };
    }

    // eslint-disable-next-line no-empty-function
    return () => {};
  }, [reference, addStickyHeaderGap]);

  return (
    <div
      className={classname}
      style={{ maxWidth: width, marginLeft }}
      ref={reference}
    >
      {children}
    </div>
  );
}

ColumnsItem.size = ColumnsSize;

export default ColumnsItem;
