import * as React from "react";
import cx from "classnames";

export enum CtaBoxAlign {
  CENTERED = "CENTERED",
}

const mapAlignToClasses = {
  [CtaBoxAlign.CENTERED]: "cta-box--centered",
};

type CtaBoxProps = {
  align?: CtaBoxAlign;
  children?: React.ReactChild | React.ReactChild[];
  full?: boolean;
};

function CtaBox(props: CtaBoxProps) {
  const { align, children, full } = props;
  const classname = cx(
    {
      "cta-box": true,
      "cta-box--full": full,
    },
    typeof align !== "undefined" && mapAlignToClasses[align],
  );

  return <div className={classname}>{children}</div>;
}

export default CtaBox;
