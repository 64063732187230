import React, { useCallback, useState } from "react";
import MobilePanel from "components/mobile-panel/mobile-panel";
import MobileMenu from "components/mobile-menu/mobile-menu";
import Logo from "components/logo/logo";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import { clickEventFromElement } from "components/google-analytics/google-analytics";
import { downloadUrl } from "lib/constants";
import lexemes from "components/lexemes/bff/lexemes";

export default function PageMobilePanel() {
  const [isActive, setIsActive] = useState(false);

  const closePanel = useCallback(() => {
    global.document.body.classList.toggle("is-opened-mobile-nav", false);
    setIsActive(false);
  }, []);

  const mobileMenuItemsTeamBumble = [
    {
      title: lexemes.lxbffLandingHeaderMenuTheShop,
      url: "https://shop.bumble.com",
      seo: {
        name: "click",
        category: "bumble-bff-landing Navigation",
        action: "The Shop",
        label: "Page mobile menu",
      },
      onClickMenuLink: (event: React.MouseEvent<HTMLAnchorElement>) => {
        clickEventFromElement(event);
      },
    },
    {
      title: lexemes.lxbffLandingHeaderMenuAmbassadors,
      url: "https://thebeehive.bumble.com/ambassadors-bumble-honey",
      seo: {
        name: "click",
        category: "bumble-bff-landing Navigation",
        action: "Ambassadors",
        label: "Page mobile menu",
      },
      onClickMenuLink: (event: React.MouseEvent<HTMLAnchorElement>) => {
        clickEventFromElement(event);
      },
    },
    {
      title: lexemes.lxbffLandingHeaderMenuEvents,
      url: "https://bumble.events",
      seo: {
        name: "click",
        category: "bumble-bff-landing Navigation",
        action: "Events",
        label: "Page mobile menu",
      },
      onClickMenuLink: (event: React.MouseEvent<HTMLAnchorElement>) => {
        clickEventFromElement(event);
      },
    },
    {
      title: lexemes.lxbffLandingHeaderMenuTheBuzz,
      url: "https://bumble.com/en/the-buzz",
      seo: {
        name: "click",
        category: "bumble-bff-landing Navigation",
        action: "The Buzz",
        label: "Page mobile menu",
      },
      onClickMenuLink: (event: React.MouseEvent<HTMLAnchorElement>) => {
        clickEventFromElement(event);
      },
    },
    {
      title: lexemes.lxbffLandingHeaderMenuAbout,
      url: "https://bumble.com/about",
      seo: {
        name: "click",
        category: "bumble-bff-landing Navigation",
        action: "About",
        label: "Page mobile menu",
      },
      onClickMenuLink: (event: React.MouseEvent<HTMLAnchorElement>) => {
        clickEventFromElement(event);
      },
    },
    {
      title: "Download the app",
      onClickMenuLink: (event: React.MouseEvent<HTMLAnchorElement>) => {
        clickEventFromElement(event);
      },
      url: downloadUrl,
      seo: {
        name: "click",
        category: "bumble-bff-landing Navigation",
        action: "Download form",
        label: "Page mobile menu",
      },
    },
  ];

  return (
    <MobilePanel
      logo={<Logo color="inherit" isOld={false} isNew={true} />}
      isActive={isActive}
      closePanel={closePanel}
      closeAction={
        <Button
          isCircle={true}
          jsClass="js-close-menu"
          icon={<Icon size="md" name={Icon.Name.Close} />}
          onClick={closePanel}
        />
      }
      menuList={[
        ...mobileMenuItemsTeamBumble.map((menuItem, index) => (
          <MobileMenu {...menuItem} key={`item-${index}`} />
        )),
      ]}
    />
  );
}
